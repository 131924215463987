<template>
    <TransitionHeight
        v-if="siteNotification"
        id="announcement-banner"
        ref="transitionHeightRef"
    >
        <div v-show="show" class="bg-grind-800 py-8 text-white shadow-inner">
            <div class="container">
                <div class="gap-4 md:flex">
                    <div class="w-full md:w-2/12 lg:w-1/12">
                        <Icon
                            id="icon"
                            name="square-exclamation-solid"
                            class="text-lg text-warning lg:text-5xl"
                        ></Icon>
                    </div>
                    <div class="w-full lg:w-7/12">
                        <h2
                            class="mb-4 text-white"
                            data-cy="announcement-title"
                        >
                            {{ siteNotification.title }}
                        </h2>
                        <p
                            class="mb-4 text-base"
                            data-cy="announcement-message"
                        >
                            {{ siteNotification.message }}
                        </p>
                        <button
                            type="button"
                            class="btn-secondary-inverse"
                            data-cy="announcement-close-btn"
                            @click="removeBanner"
                        >
                            Got it
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </TransitionHeight>
</template>

<script setup lang="ts">
import siteNotification from '~/state/SiteNotification';

const cookieName = 'announcementBannerAcknowledged';
const cookie = useCookie(cookieName, { maxAge: 24 * 60 * 60 });
const show = ref(false);
const transitionHeightRef = ref(null);

const removeBanner = () => {
    show.value = false;
    setTimeout(() => {
        cookie.value = 'true';
    }, 500);
};

watchEffect(() => {
    if (!cookie.value) {
        show.value = true;
    }
    if (show.value && siteNotification.value && transitionHeightRef.value) {
        transitionHeightRef.value.recalculateHeight();
    }
});
</script>
